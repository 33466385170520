<template>
    <div class="centered-message w-100">
        <h1 class="text-primary">{{ t('unauthorizedOrDisabledUser') }}</h1>
        <h2 class="text-primary dashboard-link">
            <b-link to="/loggingout">{{ t('gotologinpage') }}</b-link>
        </h2>
    </div>
</template>
<script>
export default {
    name: 'Unauthorized',
};
</script>
